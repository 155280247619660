// TODO maybe also move these values to Sanity?
const aisle = 2.0;
const binY = 0.649;
const spaceY = 0.1;
const shelfUpright = 0.48;
const binX = 0.449;
const spaceX = 0.05;
const cellXAs = 0.505;
const cellYAs = 0.705;
const HEIGHT_PER_BIN_AS = 0.33;
const FREEHEIGHT_AS = 2.103;
const HEIGHT_PER_BIN_MANUAL = 0.502;

/**
 * warehouseHeight minus Freeheight AS (Service Wall + Min depth) (1.095+0.008 = 2.103)
 * divided by warehouseHeight per bin AS (0.33)
 *
 * Warehouse_size_est I5:AA5
 */
const getLevelsAs = (warehouseHeight: number) =>
  (warehouseHeight - FREEHEIGHT_AS) / HEIGHT_PER_BIN_AS;

const getLevelsCappedAt16 = (levelsAs: number) => {
  // if Levels AS is less than 16
  if (levelsAs > 16) return 16;
  return levelsAs;
};

const getSpaceNeededAs = (
  binsTimesFootprintAS: number,
  levelsAsCappedAt16: number
) => binsTimesFootprintAS / levelsAsCappedAt16;

/**
 * PIO m^2 : 10% mark-up based on space outside the bins
 */
const getPioM2 = (spaceNeeded: number) => spaceNeeded * 1.1;

const getLevelsManual = (warehouseHeight: number) =>
  warehouseHeight / HEIGHT_PER_BIN_MANUAL;

const getSpaceNeededManual = (
  binsTimesFootprintManual: number,
  levelsManual: number
) => binsTimesFootprintManual / levelsManual;

/**
 * Manual m^2: 10% mark-up based on space outside the bins:
 */
const getManualM2 = (manualSqrMeters: number) => manualSqrMeters * 1.1;

/**
 * Warehouse_sizes_est E5
 */
export const getFootprintBinAS = () => cellXAs * cellYAs;

/**
 * Warehouse_sizes_est E4
 */
export const getFootprintBinManual = () =>
  (aisle / 2 + binY + spaceY + shelfUpright / 2) * (binX + spaceX);

/**
 * Warehouse_size_est
 */
export const calculateWarehouseSize = (
  warehouseHeight: number,
  bins: number
) => {
  // takes the bins and warehouseHeight, returns warehouse values
  const binsTimesFootprintManual = bins * getFootprintBinManual();
  const binsTimesFootprintAS = bins * getFootprintBinAS();

  const levelsAS = getLevelsAs(warehouseHeight);
  const levelsASCapped16 = getLevelsCappedAt16(levelsAS);

  const levelsManual = getLevelsManual(warehouseHeight);

  const spaceNeededAS = getSpaceNeededAs(
    binsTimesFootprintAS,
    levelsASCapped16
  );

  const pioSqrMeters = getPioM2(spaceNeededAS);

  const spaceNeededManual = getSpaceNeededManual(
    binsTimesFootprintManual,
    levelsManual
  );

  const manualSqrMeters = getManualM2(spaceNeededManual);

  return {
    pioSqrMeters,
    manualSqrMeters
  };
};
