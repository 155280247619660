import { INITIAL_CAPEX } from '../constants';
import { outbound } from '../data';
import { ByYear } from '../types';
import { getItemFee3PL, getOrderFee3PL } from './3pl';
import {
  findUpfrontInvestment,
  getAnnualOrders,
  getTotalBinPresentationsPerYear,
  getTotalCostOfErrorPicking,
  getWeightedNumberOfOrderlines
} from './roiCalcOverview';
import { calculateWarehouseSize } from './warehouseSizes';

/**
 * FTEs needed in PIO case
 *
 * Master_CF_overview C83:C86
 */
export const getFteNeededPerYear = (
  outbound: number,
  dailyOrders: number,
  b2cShare: number,
  annualGrowth: number,
  orderlinesB2c: number,
  orderlinesB2b: number
): ByYear => {
  const binPresentationCustomer = findBinPresentationsCustomer(
    dailyOrders,
    b2cShare,
    annualGrowth,
    orderlinesB2c,
    orderlinesB2b
  );
  return {
    year1: binPresentationCustomer.year1 / outbound,
    year2: binPresentationCustomer.year2 / outbound,
    year3: binPresentationCustomer.year3 / outbound,
    year4: binPresentationCustomer.year4 / outbound
  };
};

/**
 * Master_CF_overview D133
 * from Master_CF_overview D83:D86
 */
export const getWarehouseCostPerFTE = (
  fteCost: number,
  dailyOrders: number,
  b2cShare: number,
  annualGrowth: number,
  orderlinesB2c: number,
  orderlinesB2b: number
): ByYear => {
  const ftesNeeded = getFteNeededPerYear(
    outbound,
    dailyOrders,
    b2cShare,
    annualGrowth,
    orderlinesB2c,
    orderlinesB2b
  ).year1;
  const year1 = ftesNeeded * fteCost;
  const year2 = year1;
  const year3 = year1;
  const year4 = year1;

  return { year1, year2, year3, year4 };
};

/**
 * Bin presentations customer
 *
 * Master_CF_overview C89:C92
 */
export const findBinPresentationsCustomer = (
  dailyOrders: number,
  b2cShare: number,
  annualGrowth: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => {
  const year1 = getTotalBinPresentationsPerYear(
    dailyOrders,
    b2cShare,
    orderlinesB2c,
    orderlinesB2b
  );
  const year2 = year1 * (1 + annualGrowth);
  const year3 = year2 * (1 + annualGrowth);
  const year4 = year2 * (1 + annualGrowth);

  return {
    year1,
    year2,
    year3,
    year4
  };
};

/**
 * Master_CF_overview D125:G125
 */
export const getDeltaCashFlowPioPerYear3PL = (
  b2cShare: number,
  dailyOrders: number,
  itemsAdjFirstItemPick: number,
  annualGrowth: number,
  bins: number,
  warehouseHeight: number,
  storageFeeBin: number,
  pioPickPerFee: number,
  returnOrderFee: number,
  returnRate: number,
  fteCost: number,
  warehouseRent: number,
  orderlinesB2c: number,
  orderlinesB2b: number
): ByYear => {
  const cashflowPerYear = get3PLCashFlowPerYear(
    b2cShare,
    dailyOrders,
    itemsAdjFirstItemPick,
    annualGrowth,
    bins,
    storageFeeBin,
    returnOrderFee,
    returnRate,
    orderlinesB2c,
    orderlinesB2b
  );
  const pioCustomerCashflowPerYear = getCustomerPIOCashFlowPerYear(
    annualGrowth,
    bins,
    warehouseHeight,
    dailyOrders,
    b2cShare,
    pioPickPerFee,
    fteCost,
    warehouseRent,
    orderlinesB2c,
    orderlinesB2b
  );

  const year1 =
    cashflowPerYear.firstYear - pioCustomerCashflowPerYear.firstYear;
  const year2 =
    cashflowPerYear.secondYear - pioCustomerCashflowPerYear.secondYear;
  const year3 =
    cashflowPerYear.thirdYear - pioCustomerCashflowPerYear.thirdYear;
  const year4 =
    cashflowPerYear.forthYear - pioCustomerCashflowPerYear.forthYear;

  return { year1, year2, year3, year4 };
};

/**
 * Initial capex PIO only for first year
 *
 * Master_CF_overview D127:G127
 */
export const getCustomerPIOCashFlowPerYear = (
  annualGrowth: number,
  bins: number,
  warehouseHeight: number,
  dailyOrders: number,
  b2cShare: number,
  pioPickPerFee: number,
  fteCost: number,
  warehouseRent: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => {
  // year 1
  const storageFee = 0;
  const feesPerPickY1 = getFeesPerPick(
    dailyOrders,
    b2cShare,
    pioPickPerFee,
    orderlinesB2c,
    orderlinesB2b
  );
  const warehouseCostY1 = getWarehouseCost(
    bins,
    warehouseHeight,
    warehouseRent
  );
  const warehouseFTECostY1 = getWarehouseCostPerFTE(
    fteCost,
    dailyOrders,
    b2cShare,
    annualGrowth,
    orderlinesB2c,
    orderlinesB2b
  ).year1;
  const initialCapex = findUpfrontInvestment(bins);
  const firstYear =
    initialCapex +
    feesPerPickY1 +
    storageFee +
    warehouseCostY1 +
    warehouseFTECostY1;

  // year 2:
  const feesPerPickY2 = feesPerPickY1 * (1 + annualGrowth);
  const warehouseCostY2 = warehouseCostY1 * (1 + annualGrowth);
  const warehouseFTECostY2 = warehouseFTECostY1 * (1 + annualGrowth);
  const secondYear = feesPerPickY2 + warehouseCostY2 + warehouseFTECostY2;
  // year 3:
  const feesPerPickY3 = feesPerPickY2 * (1 + annualGrowth);
  const warehouseCostY3 = warehouseCostY2 * (1 + annualGrowth);
  const warehouseFTECostY3 = warehouseFTECostY2 * (1 + annualGrowth);
  const thirdYear =
    feesPerPickY3 + storageFee + warehouseCostY3 + warehouseFTECostY3;
  // year 4:
  const feesPerPickY4 = feesPerPickY3 * (1 + annualGrowth);
  const warehouseCostY4 = warehouseCostY3 * (1 + annualGrowth);
  const warehouseFTECostY4 = warehouseFTECostY3 * (1 + annualGrowth);
  const forthYear =
    feesPerPickY4 + storageFee + warehouseCostY4 + warehouseFTECostY4;

  return {
    firstYear,
    secondYear,
    thirdYear,
    forthYear
  };
};

/**
 * Master_CF_overview D129
 */
export const getFeesPerPick = (
  dailyOrders: number,
  b2cShare: number,
  pioPickPerFee: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => {
  const totalBinPresentations = getTotalBinPresentationsPerYear(
    dailyOrders,
    b2cShare,
    orderlinesB2c,
    orderlinesB2b
  );

  return (totalBinPresentations * pioPickPerFee) / 1000;
};

/**
 * Master_CF_overview D132
 */
export const getWarehouseCost = (
  bins: number,
  warehouseHeight: number,
  warehouseRent: number
) => {
  const { pioSqrMeters } = calculateWarehouseSize(warehouseHeight, bins);

  return ((warehouseRent * pioSqrMeters) / 1000) * 12;
};

/**
 * Master_CF_Overview D136:G136
 */
export const get3PLCashFlowPerYear = (
  b2cShare: number,
  dailyOrders: number,
  itemsAdjFirstItemPick: number,
  annualGrowth: number,
  bins: number,
  storageFeeBin: number,
  returnOrderFee: number,
  returnRate: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => {
  //  year1:
  const skuFeeY1 = getSKUFee(
    b2cShare,
    dailyOrders,
    itemsAdjFirstItemPick,
    orderlinesB2c,
    orderlinesB2b
  );
  const orderFeeY1 = getOrderFee(dailyOrders, b2cShare);
  const storageFee = getStorageFee(storageFeeBin, bins);
  const returnRateY1 = getReturnFee(dailyOrders, returnOrderFee, returnRate);

  const firstYear =
    INITIAL_CAPEX + skuFeeY1 + orderFeeY1 + storageFee + returnRateY1;

  // year2:
  const skuFeeY2 = skuFeeY1 * (1 + annualGrowth);
  const orderFeeY2 = orderFeeY1 * (1 + annualGrowth);
  const returnRateY2 = returnRateY1 * (1 + annualGrowth);
  const secondYear =
    INITIAL_CAPEX + skuFeeY2 + orderFeeY2 + storageFee + returnRateY2;
  // year3:
  const skuFeeY3 = skuFeeY2 * (1 + annualGrowth);
  const orderFeeY3 = orderFeeY2 * (1 + annualGrowth);
  const returnRateY3 = returnRateY2 * (1 + annualGrowth);
  const thirdYear =
    INITIAL_CAPEX + skuFeeY3 + orderFeeY3 + storageFee + returnRateY3;
  // year4:
  const skuFeeY4 = skuFeeY3 * (1 + annualGrowth);
  const orderFeeY4 = orderFeeY3 * (1 + annualGrowth);
  const returnRateY4 = returnRateY3 * (1 + annualGrowth);
  const forthYear =
    INITIAL_CAPEX + skuFeeY4 + orderFeeY4 + storageFee + returnRateY4;
  return { firstYear, secondYear, thirdYear, forthYear };
};

/**
 * Master_CF_overview D139
 */
export const getSKUFee = (
  b2cShare: number,
  dailyOrders: number,
  itemsAdjFirstItemPick: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => {
  const weightedNrOfOrderlines = getWeightedNumberOfOrderlines(
    b2cShare,
    orderlinesB2c,
    orderlinesB2b
  );
  const itemFee = getItemFee3PL(b2cShare, dailyOrders);

  return (
    (dailyOrders *
      360 *
      (weightedNrOfOrderlines - itemsAdjFirstItemPick) *
      itemFee) /
    1000
  );
};

/**
 * Master_CF_overview D140
 */
export const getOrderFee = (dailyOrders: number, b2cShare: number) => {
  const orderFee3pl = getOrderFee3PL(b2cShare, dailyOrders);
  const annualOrders = getAnnualOrders(dailyOrders, b2cShare);

  return (orderFee3pl * annualOrders) / 1000;
};

/**
 * Master_CF_overview D141
 */
export const getStorageFee = (storageFeeBin: number, bins: number) =>
  ((storageFeeBin * bins) / 1000) * 12;

/**
 * Master_CF_Overview D142
 */
export const getReturnFee = (
  dailyOrders: number,
  returnOrderFee: number,
  returnRate: number
) => ((dailyOrders * 360 * returnOrderFee) / 1000) * returnRate;

/**
 * Manual vs Pio per year
 *
 * Master_CF_overview D147:G147
 */
export const getDeltaCashFlowPerYearManual = (
  b2cShare: number,
  dailyOrders: number,
  bins: number,
  warehouseHeight: number,
  annualGrowth: number,
  pioPickPerFee: number,
  fteCount: number,
  fteCost: number,
  warehouseRent: number,
  warehouseSize: number,
  errorRate: number,
  orderlinesB2c: number,
  orderlinesB2b: number
): ByYear => {
  const manualFulfilmentOutflowPerYear = getManualFulfillmentOutflowPerYear(
    b2cShare,
    dailyOrders,
    annualGrowth,
    warehouseRent,
    warehouseSize,
    fteCount,
    fteCost,
    errorRate
  );

  const customerPioCashflowPerYear = getCustomerPIOCashFlowPerYear(
    annualGrowth,
    bins,
    warehouseHeight,
    dailyOrders,
    b2cShare,
    pioPickPerFee,
    fteCost,
    warehouseRent,
    orderlinesB2c,
    orderlinesB2b
  );

  const year1 =
    manualFulfilmentOutflowPerYear.firstYear -
    customerPioCashflowPerYear.firstYear;
  const year2 =
    manualFulfilmentOutflowPerYear.secondYear -
    customerPioCashflowPerYear.secondYear;
  const year3 =
    manualFulfilmentOutflowPerYear.thirdYear -
    customerPioCashflowPerYear.thirdYear;
  const year4 =
    manualFulfilmentOutflowPerYear.forthYear -
    customerPioCashflowPerYear.forthYear;

  return { year1, year2, year3, year4 };
};

/**
 * Master_CF_overview D149:G149
 */
export const getManualFulfillmentOutflowPerYear = (
  b2cShare: number,
  dailyOrders: number,
  annualGrowth: number,
  warehouseRent: number,
  warehouseSize: number,
  fteCount: number,
  fteCost: number,
  errorRate: number
) => {
  // year1:
  const fteCostY1 = getFTECostManual(fteCount, fteCost);
  const warehouseCost = getWarehouseCostManual(warehouseRent, warehouseSize);
  const costPickingY1 = getTotalCostOfErrorPicking(
    dailyOrders,
    b2cShare,
    errorRate
  );
  const firstYear = fteCostY1 + warehouseCost + costPickingY1;

  // year2:
  const fteCostY2 = fteCostY1 * (1 + annualGrowth);
  const costPickingY2 = costPickingY1 * (1 + annualGrowth);
  const secondYear = fteCostY2 + warehouseCost + costPickingY2;

  // year3:
  const fteCostY3 = fteCostY2 * (1 + annualGrowth);
  const costPickingY3 = costPickingY2 * (1 + annualGrowth);
  const thirdYear = fteCostY3 + warehouseCost + costPickingY3;
  // year4:
  const fteCostY4 = fteCostY3 * (1 + annualGrowth);
  const costPickingY4 = costPickingY3 * (1 + annualGrowth);
  const forthYear = fteCostY4 + warehouseCost + costPickingY4;

  return { firstYear, secondYear, thirdYear, forthYear };
};

/**
 * Master_CF_overview D150
 */
export const getFTECostManual = (fteCount: number, fteCost: number) =>
  fteCount * fteCost;

/**
 * Master_CF_overview D151
 */
export const getWarehouseCostManual = (
  warehouseRent: number,
  warehouseSize: number
) => {
  return ((warehouseSize * warehouseRent) / 1000) * 12;
};
