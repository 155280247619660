import { ITEM_FEE_3PL, ORDER_FEE_3PL } from '../constants';
import { compInputs3PL } from '../data';

/**
 * Note: formulas below, order and item fee, only uses the start / end fees point, and assume it has a linear development in the order interval, 0-600
 *
 * 3PL fees_table R15
 */
export const getOrderFee3PL = (b2cShare: number, dailyOrders: number) => {
  const minOrderFee = compInputs3PL[1000].orderFee;
  const maxOrderFee = compInputs3PL[17000].orderFee;

  return (
    (minOrderFee - ((minOrderFee - maxOrderFee) / 600) * dailyOrders) *
      b2cShare +
    ORDER_FEE_3PL * (1 - b2cShare)
  );
};

/**
 * 3PL fees_table R16
 */
export const getItemFee3PL = (b2cShare: number, dailyOrders: number) => {
  const minItemFee = compInputs3PL[1000].itemFee;
  const maxItemFee = compInputs3PL[17000].itemFee;

  return (
    (minItemFee - ((minItemFee - maxItemFee) / 600) * dailyOrders) * b2cShare +
    ITEM_FEE_3PL * (1 - b2cShare)
  );
};
