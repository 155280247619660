import { Chart, TooltipModel } from 'chart.js';

import { toPrice } from '../../utils/numberUtils';

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = '#fff';
    tooltipEl.style.borderRadius = '16px';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.padding = '16px';
    tooltipEl.style.display = 'flex';
    tooltipEl.style.flexDirection = 'column';
    tooltipEl.style.gap = '8px';
    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export const externalTooltipHandler = (
  context: { chart: Chart; tooltip: TooltipModel<'bar'> },
  currency: 'USD' | 'EUR'
) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleEl = document.createElement('div');
    titleEl.style.fontFamily = 'Akkurat';
    titleEl.style.fontSize = '14px';
    titleEl.style.fontWeight = '400';
    titleEl.style.lineHeight = '22px';
    titleEl.style.letterSpacing = '-0.01em';
    titleEl.style.color = '#687076';

    const titleText = document.createTextNode('Accumulated savings');

    titleEl.appendChild(titleText);

    const savingsEl = document.createElement('div');
    const savings = toPrice(
      tooltip.dataPoints?.[0]?.raw as number,
      0,
      100,
      currency
    );

    if (savings) {
      savingsEl.style.fontFamily = 'Akkurat';
      savingsEl.style.fontSize = '18px';
      savingsEl.style.fontWeight = '400';
      savingsEl.style.lineHeight = '22px';
      savingsEl.style.letterSpacing = '-0.02em';
      savingsEl.style.color = '#111E2D';

      const savingsText = document.createTextNode(savings);
      savingsEl.appendChild(savingsText);
    }

    // Remove old children
    while (tooltipEl.firstChild) {
      tooltipEl.firstChild.remove();
    }

    // Add new children
    tooltipEl.appendChild(titleEl);
    tooltipEl.appendChild(savingsEl);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  // Maybe consider making the tooltip aligned to one Y-axis or something
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
};
