import { Country, Industry, MerchantType } from '../types';
import { initialInput } from '.';
import {
  businessMap,
  itemsAdjFirstItemPick,
  merchantTypeMap,
  outbound,
  pioPickPerFee,
  returnOrderFee,
  storageFeeBin,
  warehouseFTECostPerYear,
  warehouseRentalCostPerMonth
} from './data';
import {
  findBinPresentationsCustomer,
  getDeltaCashFlowPerYearManual,
  getDeltaCashFlowPioPerYear3PL,
  getFteNeededPerYear
} from './sheets/masterCFOverview';
import {
  annualPicksPerFTE,
  findUpfrontInvestment,
  getAnnualOrders,
  getAnnualSavingsUpfrontPayment
} from './sheets/roiCalcOverview';
import { calculateWarehouseSize } from './sheets/warehouseSizes';
import { BasicInput, ByYear, Input } from './types';

const calculateCashflowPerYear = (inputValues: Input): ByYear => {
  const {
    fulfillmentType,
    dailyOrders,
    annualGrowth,
    bins,
    warehouseHeight,
    b2bShare,
    orderlinesB2c,
    orderlinesB2b,
    returnRate,
    fteCount,
    fteCost,
    warehouseRent,
    warehouseSize,
    errorRate
  } = inputValues;

  const b2cShare = 1 - b2bShare;

  if (fulfillmentType === '3pl') {
    return getDeltaCashFlowPioPerYear3PL(
      b2cShare,
      dailyOrders,
      itemsAdjFirstItemPick,
      annualGrowth,
      bins,
      warehouseHeight,
      storageFeeBin,
      pioPickPerFee,
      returnOrderFee,
      returnRate,
      fteCost,
      warehouseRent,
      orderlinesB2c,
      orderlinesB2b
    );
  }

  return getDeltaCashFlowPerYearManual(
    b2cShare,
    dailyOrders,
    bins,
    warehouseHeight,
    annualGrowth,
    pioPickPerFee,
    fteCount,
    fteCost,
    warehouseRent,
    warehouseSize,
    errorRate,
    orderlinesB2c,
    orderlinesB2b
  );
};

/**
 * ROI_calc_overview Q18
 */
export const calculateAnnualSavings = (inputValues: Input) => {
  const yearlyResults: ByYear = calculateCashflowPerYear(inputValues);

  const upfrontCost = findUpfrontInvestment(inputValues.bins);

  return (yearlyResults.year1 + upfrontCost) * 1000;
};

export const calculateBasicAnnualSavings = (input: BasicInput) => {
  const bins = getSuggestedBins(
    input.industry,
    input.skuCount,
    initialInput.merchantType
  );
  const orderlinesB2b = getSuggestedOrderlinesB2b(input.industry);
  const orderlinesB2c = getSuggestedOrderlinesB2c(input.industry);
  const warehouseRent = getSuggestedWarehouseRent(initialInput.country);
  const warehouseSize = getSuggestedWarehouseSize(
    bins,
    initialInput.warehouseHeight
  );
  const fteCount = getSuggestedFtes(
    initialInput.b2bShare / 100,
    input.dailyOrders,
    initialInput.annualGrowth,
    orderlinesB2b,
    orderlinesB2c
  );
  const fteCost = getSuggestedFteCost(initialInput.country);

  return calculateAnnualSavings({
    ...initialInput,
    ...input,
    b2bShare: initialInput.b2bShare / 100,
    errorRate: initialInput.errorRate / 100,
    returnRate: initialInput.returnRate / 100,
    fteCount,
    fteCost,
    orderlinesB2b,
    orderlinesB2c,
    bins,
    warehouseRent,
    warehouseSize
  });
};

export const calculateAccumulatedCashFlowPerYear = (
  inputValues: Input
): ByYear => {
  const yearlyResults: ByYear = calculateCashflowPerYear(inputValues);

  const year1 = yearlyResults.year1;
  const year2 = year1 + yearlyResults.year2;
  const year3 = year2 + yearlyResults.year3;
  const year4 = year3 + yearlyResults.year4;

  return {
    year1: year1 * 1000,
    year2: year2 * 1000,
    year3: year3 * 1000,
    year4: year4 * 1000
  };
};

export const calculateTotalSavings = (inputValues: Input) => {
  const yearlyResults: ByYear = calculateCashflowPerYear(inputValues);

  return (
    (yearlyResults.year1 +
      yearlyResults.year2 +
      yearlyResults.year3 +
      yearlyResults.year4) *
    1000
  );
};

/**
 * ROI_calc_overview G31
 */
export const calculateSavingsPerOrder = (inputValues: Input) => {
  const { dailyOrders, b2bShare } = inputValues;
  const b2cShare = 1 - b2bShare;
  const savings = getAnnualSavingsUpfrontPayment(inputValues);
  const annualOrders = getAnnualOrders(dailyOrders, b2cShare);

  return (savings / annualOrders) * 1000;
};

export const calculateFTEsNeeded = ({
  dailyOrders,
  b2bShare,
  annualGrowth,
  orderlinesB2c,
  orderlinesB2b
}: Input) => {
  const b2cShare = 1 - b2bShare;

  const { year1, year2, year3, year4 } = getFteNeededPerYear(
    outbound,
    dailyOrders,
    b2cShare,
    annualGrowth,
    orderlinesB2c,
    orderlinesB2b
  );
  return (year1 + year2 + year3 + year4) / 4;
};

/**
 * ROI_calc_overview G17
 */
export const getSuggestedOrderlinesB2b = (industry: Industry) =>
  businessMap[industry]?.b2b ?? 0;

/**
 * ROI_calc_overview G18
 */
export const getSuggestedOrderlinesB2c = (industry: Industry) =>
  businessMap[industry]?.b2c ?? 0;

/**
 * ROI_calc_overview G27
 */
export const getSuggestedWarehouseSize = (
  bins: number,
  warehouseHeight: number
) => {
  const warehouseSize = calculateWarehouseSize(
    warehouseHeight,
    bins
  ).manualSqrMeters;

  return warehouseSize;
};

export const calculateUpfrontInvestment = (bins: number) =>
  findUpfrontInvestment(bins) * 1000;

export const getSuggestedFteCost = (country: Country) =>
  warehouseFTECostPerYear[country];

/**
 * ROI_calc_overview G28
 */
export const getSuggestedWarehouseRent = (country: Country): number =>
  warehouseRentalCostPerMonth[country];

export const getSuggestedBins = (
  industry: Industry,
  skuCount: number,
  merchantType: MerchantType
): number => {
  const ratio = merchantTypeMap[industry]?.[merchantType] ?? 1;
  return ratio * skuCount;
};

/**
 * ROI_calc_overview G23
 * from Master_CFoverview C102
 */
export const getSuggestedFtes = (
  b2bShare: number,
  dailyOrders: number,
  annualGrowth: number,
  orderlinesB2b: number,
  orderlinesB2c: number
) => {
  const b2cShare = 1 - b2bShare;
  const binPresentations = findBinPresentationsCustomer(
    dailyOrders,
    b2cShare,
    annualGrowth,
    orderlinesB2c,
    orderlinesB2b
  );

  return binPresentations.year1 / annualPicksPerFTE();
};
