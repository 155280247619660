import {
  calculateAccumulatedCashFlowPerYear,
  calculateAnnualSavings,
  calculateBasicAnnualSavings,
  calculateSavingsPerOrder,
  calculateTotalSavings,
  calculateUpfrontInvestment,
  getSuggestedBins,
  getSuggestedFteCost,
  getSuggestedFtes,
  getSuggestedOrderlinesB2b,
  getSuggestedOrderlinesB2c,
  getSuggestedWarehouseRent,
  getSuggestedWarehouseSize
} from './calculator';

export * from './types';

export { initialInput } from './data';

export {
  calculateAccumulatedCashFlowPerYear,
  calculateAnnualSavings,
  calculateBasicAnnualSavings,
  calculateSavingsPerOrder,
  calculateTotalSavings,
  calculateUpfrontInvestment,
  getSuggestedOrderlinesB2b,
  getSuggestedOrderlinesB2c,
  getSuggestedBins,
  getSuggestedWarehouseSize,
  getSuggestedFteCost,
  getSuggestedFtes,
  getSuggestedWarehouseRent
};
