import useAsyncStore from '@hooks/store/useAsyncStore';
import useCurrencyStore from '@hooks/store/useCurrencyStore';
import Chart, {
  ActiveElement,
  ChartEvent,
  ChartItem,
  ScriptableScaleContext,
  TooltipModel
} from 'chart.js/auto';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { externalTooltipHandler } from './tooltip';

const GRID_COLOR = '#d1d0cd'; // grey4
const BACKGROUND_COLOR = '#f0efee';
const BAR_COLOR = '#687076';
const BAR_COLOR_HOVER = '#111E2D'; // navy
const TEXT_COLOR = '#687076';
const TICK_LENGTH = 0;

interface Dataset {
  label: string;
  data: number[];
}

export interface Bar {
  column: number;
  dataset: number;
  label: string;
  value: number;
}

export type ChartMode = 'grow' | 'fixed' | 'grow-striped';

type BarIndex = number;
type DatasetIndex = number;
export type HoverState = [DatasetIndex, BarIndex];

interface BarChartProps {
  datasets: Dataset[];
  labels: string[];
  // This mode is here mainly for debug purposes.
  mode?: ChartMode;
  initialHover?: HoverState;
  onHover?: (data: Bar | undefined) => void;
}

const BarChart = ({
  labels,
  datasets,
  mode = 'grow',
  onHover
}: BarChartProps) => {
  const canvas = useRef<HTMLCanvasElement>(null);
  const chartId = useRef<string | null>(null);
  const [chart, setChart] = useState<Chart>();
  const currency = useAsyncStore(useCurrencyStore, state => state.currency);

  // Hover events are triggered on every mouse-move.
  // So, we need to keep track of the hovered element and only call
  // the `onHover` whenever a new column/dataset is hovered.
  const [hover, setHover] = useState<HoverState>();
  const hoverRef = useRef<HoverState>();
  hoverRef.current = hover;
  const handleHover = useCallback(
    (e: ChartEvent, items: ActiveElement[]) => {
      if (items.length) {
        const item = items[0];
        const [curDatasetIndex, curBarIndex] = hoverRef.current || [];
        if (
          curDatasetIndex !== item.datasetIndex ||
          curBarIndex !== item.index
        ) {
          setHover([item.datasetIndex, item.index]);

          if (onHover) {
            onHover({
              column: item.index,
              dataset: item.datasetIndex,
              label: labels[item.index],
              value: item.element.x
            });
          }
        }
      }
    },
    [labels, onHover]
  );

  // Reset hover states whenever the user leaves the canvas
  const handleMouseLeave = useCallback(() => {
    setHover(undefined);
    if (onHover) {
      onHover(undefined);
    }
  }, [onHover]);

  const computedData = useMemo(
    () => ({
      labels,
      datasets: datasets.map(dataset => {
        const backgroundColor = Array(dataset.data.length)
          .fill(null)
          .map((x, i) => {
            if (typeof hover === 'undefined' && i === dataset.data.length - 1) {
              return BAR_COLOR_HOVER;
            }
            const [, barIndex] = hover || [];
            if (typeof hover !== 'undefined' && i === barIndex) {
              return BAR_COLOR_HOVER;
            }
            return BAR_COLOR;
          });
        return {
          ...dataset,
          backgroundColor
        };
      })
    }),
    [datasets, labels, hover]
  );

  // TODO; Fix this if using multiple datasets
  const max =
    mode === 'fixed' ? 1500000 : Math.max(...datasets[0].data) + 30000;
  const min =
    mode === 'fixed' ? -500000 : Math.min(...datasets[0].data) - 30000;

  const horizontalLines =
    mode === 'grow-striped'
      ? (context: ScriptableScaleContext) => {
          // Always hide the top horizontal line
          if (context.index === context?.scale?.ticks?.length - 1) {
            return BACKGROUND_COLOR;
          }
          // Always hide the bottom horizontal line
          if (context.index === 0) {
            return BACKGROUND_COLOR;
          }
          return GRID_COLOR;
        }
      : (context: ScriptableScaleContext) => {
          if (context.tick.value === 0) {
            return GRID_COLOR;
          }
          return BACKGROUND_COLOR;
        };

  const verticalLines = (context: ScriptableScaleContext) => {
    if (context.index === 0) {
      return BACKGROUND_COLOR;
    }
    return GRID_COLOR;
  };

  useEffect(() => {
    if (chartId.current !== null) {
      return;
    }
    const newChart = new Chart(canvas?.current as ChartItem, {
      type: 'bar',
      data: computedData,
      options: {
        onHover: handleHover,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: context => externalTooltipHandler(context, currency)
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            max,
            min,
            grid: {
              // Horizontal grid lines
              color: horizontalLines,
              lineWidth: 0.5,
              tickLength: TICK_LENGTH
            },
            ticks: { display: false }
          },
          x: {
            position: 'top',
            grid: {
              // Vertical grid lines
              color: verticalLines,
              lineWidth: 0.5,
              tickLength: TICK_LENGTH
            },
            ticks: {
              padding: 8,
              color: TEXT_COLOR,
              font: {
                family: 'Akkurat Mono',
                size: 13,
                weight: 'normal',
                lineHeight: '16px'
              }
            }
          }
        }
      }
    });
    setChart(newChart);
    chartId.current = newChart.id;
    return () => {
      if (chart) chart.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chart && chart.options.scales?.y?.grid) {
      chart.options.scales.y.grid.color = horizontalLines;
      chart.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [horizontalLines, mode]);

  useEffect(() => {
    if (chart) {
      // TODO: Handle multiple datasets if necessary
      chart.data.datasets[0].data = computedData.datasets[0].data;
      chart.data.datasets[0].backgroundColor =
        computedData.datasets[0].backgroundColor;
      if (chart.options?.scales?.y) chart.options.scales.y.max = max;
      if (chart.options?.scales?.y) chart.options.scales.y.min = min;
      chart.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [computedData, max, min]);

  // Updates the tooltips on currency type change
  useEffect(() => {
    if (chart && chart.tooltip) {
      chart.tooltip.options.external = context =>
        externalTooltipHandler(
          {
            chart: context.chart,
            tooltip: chart.tooltip as TooltipModel<'bar'>
          },
          currency
        );
      chart.update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  return <canvas ref={canvas} onMouseLeave={handleMouseLeave} />;
};

export default BarChart;
