import * as Yup from 'yup';

import { countries } from '../roi/data';
import {
  dailyOrdersSchema,
  emailSchema,
  fulfillmentTypeSchema,
  industrySchema,
  merchantTypeSchema,
  skuCountSchema
} from './coreValidator';

export const roiSchema = Yup.object({
  industry: industrySchema,
  dailyOrders: dailyOrdersSchema,
  fulfillmentType: fulfillmentTypeSchema,
  merchantType: merchantTypeSchema,
  country: Yup.string().oneOf(Object.keys(countries)).required(),
  annualGrowth: Yup.number().min(0).max(100).label('Annual growth').required(),
  bins: Yup.number().min(500).max(10000).required(),
  returnRate: Yup.number().min(0).max(100).label('Return rate').required(),
  b2bShare: Yup.number().min(0).max(100).label('B2B share').required(),
  orderlinesB2b: Yup.number().min(0).max(15).label('Orderlines B2B').required(),
  orderlinesB2c: Yup.number().min(0).max(5).label('Orderlines B2C').required(),
  skuCount: skuCountSchema,
  fteCost: Yup.number().label('FTE costs').required(),
  warehouseHeight: Yup.number().min(0).label('Warehouse height').required(),
  warehouseSize: Yup.number()
    .min(0)
    .max(2000)
    .label('Warehouse size')
    .required(),
  warehouseRent: Yup.number().min(5).max(15).label('Monthly rent').required()
});

export const basicRoiSchema = Yup.object({
  industry: industrySchema,
  dailyOrders: dailyOrdersSchema,
  fulfillmentType: fulfillmentTypeSchema,
  skuCount: skuCountSchema,
  companyEmail: emailSchema
});
