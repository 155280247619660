import { create } from 'zustand';

import {
  ByYear,
  calculateAccumulatedCashFlowPerYear,
  calculateAnnualSavings,
  calculateSavingsPerOrder,
  calculateTotalSavings,
  calculateUpfrontInvestment,
  Input
} from '../../roi';

interface RoiNumbers {
  annualSavings: number;
  savingsByYear: ByYear;
  savingsPerOrder: number;
  totalSavings: number;
  upfrontInvestment: number;
}

interface RoiState extends RoiNumbers {
  calculate: (input: Input) => void;
}

const useRoiStore = create<RoiState>(set => ({
  annualSavings: 0,
  savingsByYear: { year1: 0, year2: 0, year3: 0, year4: 0 },
  savingsPerOrder: 0,
  totalSavings: 0,
  upfrontInvestment: 0,
  calculate: (input: Input) =>
    set({
      annualSavings: calculateAnnualSavings(input),
      savingsByYear: calculateAccumulatedCashFlowPerYear(input),
      savingsPerOrder: calculateSavingsPerOrder(input),
      totalSavings: calculateTotalSavings(input),
      upfrontInvestment: calculateUpfrontInvestment(input.bins)
    })
}));

export default useRoiStore;
