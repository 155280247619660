import {
  BIN_COST,
  INSTALLATION_COST,
  UPFRONT_COST_ADDITIONAL
} from '../constants';
import {
  costOfErrorPick,
  itemsAdjFirstItemPick,
  pickManual,
  pioPickPerFee,
  reductionErrorPickingPio,
  returnOrderFee,
  storageFeeBin,
  upfrontCostMap
} from '../data';
import { Input } from '../types';
import {
  get3PLCashFlowPerYear,
  getCustomerPIOCashFlowPerYear,
  getManualFulfillmentOutflowPerYear
} from './masterCFOverview';

/**
 * Master_CFoverview C101
 *
 * TODO: Update to accept params?
 */
export const annualPicksPerFTE = () => pickManual * 1750 * 0.6;

/**
 * This is not shown on the UI, it's only used to calculate savings per order
 * which includes the upfront payment
 *
 * ROI_calc_overview G30
 */
export const getAnnualSavingsUpfrontPayment = (inputValues: Input): number => {
  const {
    fulfillmentType,
    dailyOrders,
    annualGrowth,
    bins,
    warehouseHeight,
    b2bShare,
    returnRate,
    fteCount,
    fteCost,
    warehouseRent,
    warehouseSize,
    errorRate,
    orderlinesB2c,
    orderlinesB2b
  } = inputValues;

  const b2cShare = 1 - b2bShare;

  const pioCustomerCashflow = getCustomerPIOCashFlowPerYear(
    annualGrowth,
    bins,
    warehouseHeight,
    dailyOrders,
    b2cShare,
    pioPickPerFee,
    fteCost,
    warehouseRent,
    orderlinesB2c,
    orderlinesB2b
  );
  const upfrontPayment = findUpfrontInvestment(bins);

  if (fulfillmentType === '3pl') {
    const cashflow3PL = get3PLCashFlowPerYear(
      b2cShare,
      dailyOrders,
      itemsAdjFirstItemPick,
      annualGrowth,
      bins,
      storageFeeBin,
      returnOrderFee,
      returnRate,
      orderlinesB2c,
      orderlinesB2b
    );

    return (
      cashflow3PL.firstYear - pioCustomerCashflow.firstYear + upfrontPayment
    );
  }

  const manualCashflow = getManualFulfillmentOutflowPerYear(
    b2cShare,
    dailyOrders,
    annualGrowth,
    warehouseRent,
    warehouseSize,
    fteCount,
    fteCost,
    errorRate
  );

  return (
    manualCashflow.firstYear - pioCustomerCashflow.firstYear + upfrontPayment
  );
};

// ******* Supportive: hidden/ background calculations *******
/**
 * ROI_calc_overview G43
 */
export const getB2CDailyOrders = (dailyOrders: number, b2cShare: number) => {
  const result = dailyOrders * b2cShare;
  return result;
};

/**
 * ROI_calc_overview G44
 */
export const getB2BDailyOrders = (dailyOrders: number, b2cShare: number) => {
  const result = (1 - b2cShare) * dailyOrders;
  return result;
};

/**
 * ROI_calc_overview G45
 */
export const getMonthlyOrders = (dailyOrders: number, b2cShare: number) => {
  return (
    (getB2CDailyOrders(dailyOrders, b2cShare) +
      getB2BDailyOrders(dailyOrders, b2cShare)) *
    30
  );
};

/**
 * ROI_calc_overview G46
 */
export const getAnnualOrders = (dailyOrders: number, b2cShare: number) =>
  getMonthlyOrders(dailyOrders, b2cShare) * 12;

/**
 * ROI_calc_overview G47
 */
export const getB2CBinPresentationsPerYear = (
  dailyOrders: number,
  b2cShare: number,
  orderlinesB2c: number
) => {
  const dailyOrderB2C = getB2CDailyOrders(dailyOrders, b2cShare);
  return orderlinesB2c * dailyOrderB2C * 360;
};

/**
 * ROI_calc_overview G48
 */
export const getB2BBinPresentationsPerYear = (
  dailyOrders: number,
  b2cShare: number,
  orderlinesB2b: number
) => {
  const dailyOrderB2b = getB2BDailyOrders(dailyOrders, b2cShare);
  return orderlinesB2b * dailyOrderB2b * 360;
};

/**
 * ROI_calc_overview G49
 */
export const getTotalBinPresentationsPerYear = (
  dailyOrders: number,
  b2cShare: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => {
  const binPresentationsB2c = getB2CBinPresentationsPerYear(
    dailyOrders,
    b2cShare,
    orderlinesB2c
  );
  const binPresentationsB2b = getB2BBinPresentationsPerYear(
    dailyOrders,
    b2cShare,
    orderlinesB2b
  );

  return binPresentationsB2c + binPresentationsB2b;
};

/**
 * ROI_calc_overview G55
 */
export const getWeightedNumberOfOrderlines = (
  b2cShare: number,
  orderlinesB2c: number,
  orderlinesB2b: number
) => b2cShare * orderlinesB2c + orderlinesB2b * (1 - b2cShare);

/**
 * Master_CF_overview D153
 *
 * from ROI_calc_overview G66
 */
export const getTotalCostOfErrorPicking = (
  dailyOrders: number,
  b2cShare: number,
  errorRate: number
) => {
  return (
    (getErrorInPicking(errorRate) *
      costOfErrorPick *
      getAnnualOrders(dailyOrders, b2cShare)) /
    1000
  );
};

/**
 * ROI_calc_overview G68
 */
export const getErrorInPicking = (errorRate: number) =>
  errorRate * reductionErrorPickingPio;

const lookupGridCostPerBin = (bins: number) => {
  const keys = Object.keys(upfrontCostMap)
    .map(v => Number(v))
    .sort((a, b) => (a < b ? 1 : -1));
  for (const key of keys) {
    if (bins >= key) return upfrontCostMap[key as keyof typeof upfrontCostMap];
  }

  // Use the lowest value (with the highest grid cost) as fallback for bin counts below 200
  return upfrontCostMap['200'];
};

/**
 * ROI_calc_overview H91
 */
export const findUpfrontInvestment = (bins: number) => {
  const gridCostPerBin = lookupGridCostPerBin(bins);

  const upfrontInvestment =
    ((gridCostPerBin + BIN_COST) * bins + UPFRONT_COST_ADDITIONAL) *
    (1 + INSTALLATION_COST);
  return upfrontInvestment / 1000;
};
